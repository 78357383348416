.modal {
	width: 100vw;
	height: 100vh;
	z-index: -10;
	position: fixed;
	top: 0;
	transform-origin: top center;
	transform: translateY(-100%);
	transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.modal h2 {
	font-size: 1.7em;
}

.modal-open {
	z-index: 20;
	transform: translateY(0%);
	background: rgba(0, 0, 0, 0.808);
	backdrop-filter: blur(100px);
	transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.modal-content {
	width: 100vw;
	display: flex;
	height: 100vh;
	gap: 30px;
	justify-content: space-evenly;
	align-self: center;
	position: relative;
	overflow: scroll;
}

.modal-text {
	width: 400px;
	padding: 40px;
}

.fullscreen {
	display: none;
}

.modal-img {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	flex: 1;
	transition: all 0.4s ease-in-out;
	cursor: zoom-in;
}

.arrow-buttons-container {
	position: absolute;
	right: 20px;
	bottom: 3vh;
	font-size: 2em;
	display: flex;
	gap: 20px;
}

@media only screen and (min-width: 489px) and (max-width: 1200px) {
	/* For mobile: */
	.modal-content {
		padding-top: 3em;
		flex-direction: column;
	}

	.modal-text {
		width: 100%;
	}
}

@media only screen and (max-width: 489px) {
	/* For mobile: */
	.modal-content {
		padding: 20px;
		flex-direction: column;
		gap: 0px;
	}

	.arrow-buttons-container {
		right: unset;
		padding: 8px;
		gap: 0;
		width: 100%;
		top: 70px;
		bottom: unset;
	}

	.modal-img {
		flex: 50%;
	}

	.modal-text {
		flex: 1;
		width: unset;
		padding: 10px;
		padding-top: 30px;
	}

	.left-arrow-button {
		padding: 100% 25%;
		-webkit-tap-highlight-color: transparent;
	}
}

a#project-link {
	margin-top: 10px;
	font-weight: 600;
	display: flex;
	align-items: center;
	color: #6094ff;
}

a#project-link:hover {
	filter: brightness(130%);
}

a#project-link svg {
	display: inline-block;
	font-size: 1.8em;
}

#fullscreen-img {
	height: fit-content;
	cursor: zoom-out;
}
