:root {
  --main-bg: #121212;
  --gold: rgb(135 113 45);
  box-sizing: border-box;
}

.nav-other-options-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 23px;
}


@media only screen and (min-width: 490px) {
  /* For desktop: */
  .nav-other-options-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}


@keyframes loadNav {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.nav {
  letter-spacing: 0;
  font-family: "Bebas Neue", new-order, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 20px 40px;
  z-index: 2;
  animation: loadNav 1s linear;
}

.nav p,
h2 {
  margin: 0;
}

a {
  text-decoration: none;
}


html {
  background-color: var(--main-bg);
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  position: relative;
  font-style: normal;
}

a p {
  letter-spacing: 4px;
  font-size: 0.8em;
  text-transform: uppercase;
}

ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 8px 0;
  list-style-type: none;
}

ul ul {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 10px;
  position: absolute;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.261);
}

ul ul a p {
  letter-spacing: 1.5px;
  font-size: 0.8em;
  text-transform: uppercase;
  text-decoration: none;
}

ul ul.active, ul ul:hover{
  height: 120px;
  opacity: 1;
  transition: height 300ms, opacity 600ms;
  text-transform: uppercase;
}

/* Underline styles */
li a {
  letter-spacing: 4px;
  display: block;
  position: relative;
  padding: 0.2em 0;
  text-transform: uppercase;
}

/* Fade in */

li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -2px;
  width: 50px;
  height: 0.2em;
  background-color: var(--gold);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

li a:hover::after,
li a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
}

/* Slide in */
li a {
  overflow: hidden;
}

li a::after {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

li a:hover::after,
li a:focus::after {
  transform: translate3d(0, 0, 0);
}

.main-layout {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.menu {
  visibility: hidden;
  position: absolute;
}
