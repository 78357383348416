.projects-gallery {
  display: flex;
    flex: 1;
    height: 100%;
    flex-wrap: wrap;
    cursor: pointer;
  overflow: hidden;

}

@media only screen and (min-width: 800px) {
  .projects-gallery {
    margin: 0 40px;
  }
}


.projects-gallery > div {
  flex: 1;
  min-width: 300px;
  transition: all .4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.projects-gallery > div:hover {
  flex: 3;
  min-width: 300px;
  transform: scale(1.25);
}

.projects .banner {
  margin-bottom: 60px;
  height: 220px;
  overflow: hidden;
}

.projects p,
h1 {
  font-size: 20px;
  line-height: 1.5em;
  word-spacing: 2px;
  padding: 30px;
}

.projects h1, .projects h2 {
  font-size: 60px;
  line-height: 1em;
}
.projects h2 {
  line-height: 2.5em;
  font-size: 40px;
}

.projects-gallery .overlay p {
  display: block !important;
}

.projects-gallery .container .overlay {
  opacity: 1;
}
.projects-gallery .container:hover {
  filter: grayscale();
}

.featherfell-container {
  max-width: 1010px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
  margin: 0 auto;
}

.fish-container {
  max-width: 1560px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
  margin: 0 auto;
}

.aswang-container {
  max-width: 1560px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
  margin: 0 auto;
}

.fish-gallery {
  position: relative;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 28vw));
  gap: 30px;
  grid-template-rows: repeat(3);
  grid-template-areas:
    "a b c"
    "d d d"
    "blank1 e blank2";
}

.trailer {
  position: relative;
  display: grid;
  overflow: scroll;
  width: 100%;

  grid-template-columns: repeat(3, 275px) 147px;
  gap: 12px;
  grid-template-rows: repeat(2);
  grid-template-areas:
    "a b c e"
    "d d d e";
}

.aswang-gallery {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 60px;
  /* grid-template-columns: 1fr 1fr; */
}

.aswang-gallery-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.aswang-gallery-section > p {
  max-width: 400px;
}
