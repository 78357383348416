@keyframes load {
  0% {
    z-index: 0;
    opacity: 1;
  }
  40% {
    z-index: 0;
    opacity: 1;
  }
  90% {
    z-index: 0;
    opacity: 0;
  }
}

.about-overlay {
  height: 100%;
  width: 100vw;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  animation: load 1s ease;
}

.hi {
  z-index: 1;
}

.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1250px;
  margin: 120px auto 0px;
  gap: 100px;
  flex-wrap: wrap;
}

.profile-img {
  border-radius: 250px;
  max-width: 400px;
  width: 100%;
  background: var(--main-bg);
  object-fit: cover;
  align-self: flex-start;
}

.gold-text {
  color: var(--gold);
}

.about-info-section {
  flex: 1;
  font-size: 20px;
  line-height: 1.5em;
  word-spacing: 2px;
  padding: 0 30px;
}

@media only screen and (max-width: 490px) {
  .about-container {
    margin: 0px;
    gap: 20px;
    flex-wrap: wrap;
  }

  .profile-img {
    background: var(--main-bg);
    object-fit: cover;
    min-height: 500px;
    flex: 50%;
    border-radius: 0;
    max-width: unset;
    filter: brightness(0.7);
  }
}
