@media only screen and (min-width: 490px) and (max-width: 799px) {
	/* For mobile: */
	.gallery {
		display: grid;
		grid-template-columns: repeat(3, minmax(auto, auto));
	}

	.container {
		grid-area: unset !important;
	}
}

@media only screen and (max-width: 490px) {
	/* For mobile: */
	.img {
		width: 100%;
		transform: unset !important;
		scale: unset !important;
	}
}
