@keyframes loadGallery {
  0% {
    opacity: 0;
    position: relative;
    /* left: -300vh; */
    top: -10px;
  }
  55% {
    opacity: .3;
  }
  100% {
    opacity: 1;
    position: relative;
    /* left: 0px; */
    top: 0px;
  }
}

.container {
  position: relative;
  cursor: pointer;
  animation: loadGallery .3s linear;
}
.container:hover {
  filter: grayscale();
}

.img {
  display: block;
  object-fit: cover;
  width: 100%;
  opacity: 100%;
  scale: 60%;
  transform: translateY(-50vh);
  transform-origin: top;
  background-size: cover;
  background-position: center;
  clip-path: inset(0);
  filter: blur(20px);
}

.loaded {
  height: 100%;
  opacity: 100%;
  scale: 100%;
  background-size: cover;
  transform: translateY(0);
  background-position: center;
  filter: blur(0px);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: 0.2s ease;
  background-color: rgba(0, 0, 0, 0.4);
  mix-blend-mode: unset;
  color: white;
  font-family: "Rozha One", serif;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reverse-color {
  filter: grayscale();
}

.reverse-color:hover {
  filter: none !important;
}
.reverse-color:hover img {
  filter: brightness(2);
}

.reverse-color:hover .overlay p {
  background-color: var(--gold);
  padding: 10px;
  border-radius: 10px;
}

.overlay p {
  display: hidden !important;
  break-word: break-all;
}

.container:hover .overlay {
  opacity: 1;
}

.container:hover.active div.img div.overlay {
  display: hidden !important;
}
