@media only screen and (max-width: 490px) {
  /* For mobile: */
  .nav {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    width: 100px;
    padding: 12px;
    padding-top: 100px;
    padding-left: 30px;
    position: fixed;
    gap: 20px;
    font-size: 1.3em;
    transition: all 0.2s ease-in-out;
  }

  .main-layout {
    overflow-x: hidden;
  }

  .gallery,
  .about-container,
  .projects-gallery {
    width: 100vw;
    transition: all 0.23s ease-in-out;
  }

  .img {
    padding: 5px 0;
  }

  .nav ul {
    flex-direction: column;
    justify-content: unset;
    gap: 16px;
  }

  .nav .social-media-container {
    flex-direction: column;
    gap: 26px;
  }

  .nav li a {
    letter-spacing: 0px;
    padding: 0;
    text-transform: unset;
  }

  .menu {
    width: fit-content;
    position: fixed;
    visibility: visible;
    font-size: 1.2em;
    padding: 30px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 1.3em;
    z-index: 3;
  }

  .menu::before {
    content: "";
    position: absolute;
    height: 26%;
    left: 23px;
    /* border-left: 5px solid var(--main-bg); */
    border-left: 5px solid var(--gold);
  }

  .nav-closed .nav {
    transform: translateX(-110px);
  }

  .nav-open .nav {
    transform: translateX(0);
  }

  .nav-closed .gallery,
  .about-container {
    margin-left: 0px;
  }

  .nav-closed .about-container {
    margin-left: 0px;
  }

  .projects-gallery {
    margin: 0;
    width: 100vw;
  }

  .nav-open .gallery,
  .about-container, .nav-open .projects-gallery {
    margin-left: 120px;
  }

  ul ul {
    position: relative;
    margin: 0;
    padding: 0;
  }

  ul ul.active, ul ul:hover{
    height: 220px;
    margin: 8px 0;
    padding: 10px;
  }
}
