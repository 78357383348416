.contact p {
  color: white;
  font-size: 20px;
  line-height: 1.5em;
  word-spacing: 2px;
}

.contact {
  width: 100%;
}

.contact form {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.contact h2 {
  font-size: 1.85em;
  line-height: 3em;
}

p.thankyou {
  font-weight: semibold;
  font-size: 1em;
}
