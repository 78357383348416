@media only screen and (min-width: 800px) {
  /* For desktop: */
  .gallery {
    position: relative;
    cursor: pointer;
    background: var(--main-bg);
  }

  .gallery-cats {
    display: grid;
    background: var(--main-bg);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .gallery-main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 1920px;
  }

  .gallery-main > div {
    display: inherit;
  }

  /* row 1 */
  .gallery-main > div:nth-child(1) {
    height: 462.58px;
  }

  /* row 2 */
  .gallery-main > div:nth-child(2) {
    height: 399.087px;
  }

  /* row 3 */
  .gallery-main > div:nth-child(3) {
    height: 360.105px;
  }

  /* row 4 */
  .gallery-main div:nth-child(4) {
    height: 358.8px;
  }
}

@media (min-width: 800px) and (max-width: 1500px) {
  .gallery-cats {
    display: grid;
    background: var(--main-bg);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
